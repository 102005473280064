//Suites 1 recamara

//Deluxe jr suite
import image1 from "../../assets/Suites/Suites 1 Recamara/DeluxeJuniorSuite/DeluxeJuniorSuite-1.webp"
import image2 from "../../assets/Suites/Suites 1 Recamara/DeluxeJuniorSuite/DeluxeJuniorSuite-2.webp"
import image3 from "../../assets/Suites/Suites 1 Recamara/DeluxeJuniorSuite/DeluxeJuniorSuite-3.webp"
import image4 from "../../assets/Suites/Suites 1 Recamara/DeluxeJuniorSuite/DeluxeJuniorSuite-4.webp"
import image5 from "../../assets/Suites/Suites 1 Recamara/DeluxeJuniorSuite/DeluxeJuniorSuite-5.webp"

const fullvieworder = [
    image1,
    image2,
    image3,
    image4,
    image5
]

const DeluxeJuniorSuiteMedia = {
    "images":[
        {
        "original":image1,
        "thumbnail":image1
        },
        {
        "original":image2,
        "thumbnail":image2
        },
        {
        "original":image3,
        "thumbnail":image3
        },
        {
        "original":image4,
        "thumbnail":image4
        },
        {
        "original":image5,
        "thumbnail":image5
        }
    ] 
}

const DeluxeJuniorSuiteview = {
    "images":[
        {
        "original":image1,
        "thumbnail":image1
        },
        {
        "original":image2,
        "thumbnail":image2
        },
        {
        "original":image3,
        "thumbnail":image3
        },
        {
        "original":image4,
        "thumbnail":image4
        },
        {
        "original":image5,
        "thumbnail":image5
        }
    ] 
}

export {DeluxeJuniorSuiteMedia, DeluxeJuniorSuiteview };