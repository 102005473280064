//Suites 2 recamaras

// Master Suite
import image1 from "../../assets/Suites/Suites 2 Recamaras/MasterSuite/MasterSuite-1.webp"
import image2 from "../../assets/Suites/Suites 2 Recamaras/MasterSuite/MasterSuite-2.webp"
import image3 from "../../assets/Suites/Suites 2 Recamaras/MasterSuite/MasterSuite-3.webp"
import image4 from "../../assets/Suites/Suites 2 Recamaras/MasterSuite/MasterSuite-4.webp"
import image5 from "../../assets/Suites/Suites 2 Recamaras/MasterSuite/MasterSuite-5.webp"
import image6 from "../../assets/Suites/Suites 2 Recamaras/MasterSuite/MasterSuite-6.webp"
import image7 from "../../assets/Suites/Suites 2 Recamaras/MasterSuite/MasterSuite-7.webp"
import image8 from "../../assets/Suites/Suites 2 Recamaras/MasterSuite/MasterSuite-8.webp"

const MasterSuite = {
    "images":[
        {
        "original":image1,
        "thumbnail":image1
        },
        {
        "original":image2,
        "thumbnail":image2
        },
        {
        "original":image3,
        "thumbnail":image3
        },
        {
        "original":image4,
        "thumbnail":image4
        },
        {
        "original":image5,
        "thumbnail":image5
        },
        {
        "original":image6,
        "thumbnail":image6
        },
        {
        "original":image7,
        "thumbnail":image7
        },
        {
        "original":image8,
        "thumbnail":image8
        }
    ] 
}

export default MasterSuite;