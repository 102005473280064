import "./Slider.css";
import { Box, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";

//Carrousel images
import image1 from "../assets/sliderppal/img1.webp";
import image2 from "../assets/sliderppal/img2.webp";
import image3 from "../assets/sliderppal/img3.webp";
import image4 from "../assets/sliderppal/img4.webp";

export default function Slider() {
  const images = [image1, image2, image3, image4];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 3000);
    // Limpieza del intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  useEffect(() => {
    // Crear una lista de promesas de carga de imágenes
    const loadImagePromises = images.map(
      (src) =>
        new Promise((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve();
        })
    );

    // Cuando todas las imágenes hayan terminado de cargarse
    Promise.all(loadImagePromises).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <Box className="imageGalery">
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={400} />
      ) : (
        <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} />
      )}
    </Box>
  );
}