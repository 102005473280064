import { Box, Typography } from "@mui/material";
import ReactPlayer from 'react-player';

import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import "./FullInfoSuitesView.css";

//Logos de caracteristicas 
import ruler from "../assets/icons/ruler-white.webp";
import bed from "../assets/icons/bed-white.webp"
import sink from "../assets/icons/sink-white.webp";
import dinning from "../assets/icons/dinning-table-white.webp";
import kitchen from "../assets/icons/kitchen-white.webp";
import fridge from "../assets/icons/fridge-white.webp";
import microwave from "../assets/icons/microwave-white.webp";
import coffee from "../assets/icons/coffee-machine-white.webp";
import blender from "../assets/icons/blender-white.webp";
import air from "../assets/icons/snowflake-white.webp";
import tv from "../assets/icons/smart-tv-white.webp";


export default function FullInfoSuitesView({language, suitesmedia}){

        // Fuente Textos 
        let textfont="Century Gothic"
        // Fuente de caracteristicas
        let font ="Century Gothic Italic"
        // Tamaño de fuente de caracteristicas
        let textSize ="1.3rem"

        //Array de iconos de caracteristicas
        let caracteristicas = [ruler, bed, sink, dinning, kitchen, fridge, microwave, coffee,
            blender, air, tv]
        let caracteristicas2 = [kitchen, fridge, microwave, coffee,
                blender, air, tv]    
        

    return(
            <Box width={"100%"} sx={{gridColumnStart:2, gridColumnEnd:3}}> 
                <Box width={"100%"} className="containerFullSuitesView" >

                    <Box className="fullviewtittle" >
                        <Typography textAlign={"center"} fontSize={"2rem"} fontFamily={"Century Gothic"}>
                            {language.tittle}
                        </Typography>
                    </Box>


                    <Box className="fullviewtextos" >
                        <Typography textAlign={"left"} fontSize={"1rem"} fontFamily={textfont} pb={2}>
                            {language.text2}
                        </Typography>
                        <Typography textAlign={"left"} fontSize={"1rem"} fontFamily={textfont} pb={2}>
                            {language.text3}
                        </Typography>
                        <Typography textAlign={"left"} fontSize={"1rem"} fontFamily={textfont} pb={2}>
                            {language.text4}
                        </Typography>    
                    </Box>

                    <Box className="fullviewbtn" display={"flex"} pt={"1rem"}>
                    <a href={language.textbutton2Link}>
                        <button className="fullViewMainbtn"  >
                            <Typography color={"white"} fontSize={"1rem"} fontFamily={"Century Gothic"}>
                                {language.textbutton2}
                            </Typography>
                        </button>
                    </a>
                    </Box>

                    <Box className="video" >
                    <ReactPlayer
                        url={language.video}
                        width={"100%"}
                        height={"350px"}
                        controls
                        loop
                    />
                    </Box>
                </Box>

             

                <Box width={"100%"} className="containerFullSuitesFotos">
                    {
                        suitesmedia.images.map((image, index) => (
                            <img className="fullviewimg" key={index} src={image.original} alt="suite photo" loading="lazy"/>
                        ))
                    }
                </Box>


                <Box className="containerFullSuitesChar">
                    {
                        caracteristicas.map((icon, index) => (
                            <Box className="fullviewchar1 flex charitem" >
                                <img className="fullimgChar" src={icon} alt="characteristic icon" loading="lazy"/>
                                <Typography textAlign={"left"} fontSize={textSize} fontFamily={font} color={"white"}>
                                    {language[`char${index + 1}`]}
                                 </Typography>
                            </Box>
                        ))
                    
                    }  
                    {
                        language.char25 && (
                            <Box className="fullviewchar1 flex charitem" sx={{gridColumn:3, gridRow:1}}>
                                <img className="fullimgChar" src={bed} />
                                <Typography textAlign={"left"} fontSize={textSize} fontFamily={font} color={"white"}>
                                    {language.char25}
                                 </Typography>
                            </Box>
                        )
                    }
                     {
                        language.char26 && (
                            <Box className="fullviewchar1 flex charitem" sx={{gridColumn:1, gridRow:2}}>
                                <img className="fullimgChar" src={bed} />
                                <Typography textAlign={"left"} fontSize={textSize} fontFamily={font} color={"white"}>
                                    {language.char26}
                                 </Typography>
                            </Box>
                        )
                    }
                </Box>

                {/*Elemento para visualizacion de caracteristicas en pantalla pequeña*/}
                <Box className="containerFullSuitesChar2">

                    <Box className="  charitem" >
                        <img className="fullimgChar" src={caracteristicas[0]} />
                        <Typography textAlign={"center"} fontSize={"1rem"} fontFamily={font} color={"white"}>
                            {language.char1}
                        </Typography>
                    </Box>

                    <Box className="  charitem" >
                        <img className="fullimgChar" src={caracteristicas[1]} />
                        <Typography textAlign={"center"} fontSize={"1rem"} fontFamily={font} color={"white"}>
                            {language.char2}
                        </Typography>
                    </Box>
                    {
                        language.char25 && (
                            <Box className=" flex charitem" >
                                <img className="fullimgChar" src={bed} />
                                <Typography textAlign={"left"} fontSize={"1rem"} fontFamily={font} color={"white"}>
                                    {language.char25}
                                 </Typography>
                            </Box>
                        )
                    }
                     {
                        language.char26 && (
                            <Box className=" flex charitem" >
                                <img className="fullimgChar" src={bed} />
                                <Typography textAlign={"left"} fontSize={"1rem"} fontFamily={font} color={"white"}>
                                    {language.char26}
                                 </Typography>
                            </Box>
                        )
                    }
                    <Box className="  charitem" >
                        <img className="fullimgChar" src={caracteristicas[2]} />
                        <Typography textAlign={"center"} fontSize={"1rem"} fontFamily={font} color={"white"}>
                            {language.char3}
                        </Typography>
                    </Box>

                    <Box className="  charitem" >
                        <img className="fullimgChar" src={caracteristicas[3]} />
                        <Typography textAlign={"center"} fontSize={"1rem"} fontFamily={font} color={"white"}>
                            {language.char4}
                        </Typography>
                    </Box>

                   
                    {
                        caracteristicas2.map((icon, index) => (
                            <Box className=" charitem" justifyItems={"center"}>
                                <img className="fullimgChar" src={icon} />
                                <Typography textAlign={"center"} fontSize={"1rem"} fontFamily={font} color={"white"}>
                                    {language[`char${index + 5}`]}
                                 </Typography>
                            </Box>
                        ))
                    }  

                </Box>
                <Box className="imgGalleryFullInfoSuites"  alignSelf={"center"} justifySelf={"center"}
                    width={"95%"} padding={1}>
                  <ReactImageGallery
                    lazyLoad
                    items={suitesmedia.images}
                    showBullets
                    showFullscreenButton={true}
                    showNav={false}
                    thumbnailPosition={"left"}
                    /> 
                </Box>
            </Box>
    )
}