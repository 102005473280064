//Suites 1 recamara

//PremiumJuniorDoubleSuite
import image1 from "../../assets/Suites/Suites 1 Recamara/PremiumJuniorSuiteDoble/PremiumJuniorSuiteDoble-1.webp"
import image2 from "../../assets/Suites/Suites 1 Recamara/PremiumJuniorSuiteDoble/PremiumJuniorSuiteDoble-2.webp"
import image3 from "../../assets/Suites/Suites 1 Recamara/PremiumJuniorSuiteDoble/PremiumJuniorSuiteDoble-3.webp"
import image4 from "../../assets/Suites/Suites 1 Recamara/PremiumJuniorSuiteDoble/PremiumJuniorSuiteDoble-4.webp"
import image5 from "../../assets/Suites/Suites 1 Recamara/PremiumJuniorSuiteDoble/PremiumJuniorSuiteDoble-5.webp"
import image6 from "../../assets/Suites/Suites 1 Recamara/PremiumJuniorSuiteDoble/PremiumJuniorSuiteDoble-6.webp"
import image7 from "../../assets/Suites/Suites 1 Recamara/PremiumJuniorSuiteDoble/PremiumJuniorSuiteDoble-7.webp"
import image8 from "../../assets/Suites/Suites 1 Recamara/PremiumJuniorSuiteDoble/PremiumJuniorSuiteDoble-8.webp"
import image9 from "../../assets/Suites/Suites 1 Recamara/PremiumJuniorSuiteDoble/PremiumJuniorSuiteDoble-9.webp"

const PremiumJuniorDoubleSuite = {
    "images":[
        {
        "original":image1,
        "thumbnail":image1
        },
        {
        "original":image2,
        "thumbnail":image2
        },
        {
        "original":image3,
        "thumbnail":image3
        },
        {
        "original":image4,
        "thumbnail":image4
        },
        {
        "original":image5,
        "thumbnail":image5
        },
        {
        "original":image6,
        "thumbnail":image6
        },
        {
        "original":image7,
        "thumbnail":image7
        },
        {
        "original":image8,
        "thumbnail":image8
        },
        {
        "original":image9,
        "thumbnail":image9
        }
    ] 
}

export default PremiumJuniorDoubleSuite;